import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import { Link } from 'gatsby'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import AudioSet from '../../components/audioset'
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'
import BlockQuote from '../../components/blockquote'

const characters = [
  {name: 'Alfredo Germont', voice: 'Tenor', desc: 'A young, deeply romantic, but rather foolish man with an angry streak. Makes off with Violetta.'},
  {name: 'Annina', voice: 'Soprano', desc: 'Violetta\'s maid. Loyal to the very end.'},
  {name: 'Baron Douphol', voice: 'Baritone', desc: 'Pays Violetta to be his lover. Loses a great deal of money to Alfredo at the gambling table.'},
  {name: 'Dr. Grenvil', voice: 'Bass', desc: 'Violetta\'s doctor. He can\'t do much for her as TB was at this time incurable.'},
  {name: 'Flora Bervoix', voice: 'Mezzo-soprano', desc: 'A friend of Violetta and also one of the great non-parts in opera. Frequently onstage yet never noticed.'},
  {name: 'Gastone', voice: 'Tenor', desc: 'A friend of Alfredo\'s who brings Alfredo to a party so he can meet Violetta.'},
  {name: 'Giorgio Germont', voice: 'Baritone', desc: 'The stoically old fashioned father of Alfredo. He comes to understand young love by the conclusion but it takes a lot of pain and suffering from all involved to get him there.'},
  {name: 'Violetta Valéry', voice: 'Soprano', desc: 'The heroine of the piece. A courtesan with a heart (think Moulin Rouge) who comes to a predictably sticky end.'},
];

const inbrief = {name: 'La Traviata', translatedname: 'The Fallen Woman', composer: "Giuseppe Verdi", librettist: "Francesco Maria Piave", language: "Italian", date: 'March 6th, 1853', acts: "Three", musiclength: "Two hours, fifteen minutes"};

const bibliography = [
  {name: 'La Traviata', writer: 'Overture Opera Guides', url: 'https://amzn.to/3mQTnlL'},
  {name: 'Verdi With a Vengeance: An Energetic Guide to the Life and Complete Works of the King of Opera', writer: 'William Berger', url: 'https://amzn.to/3B3Qlyq'},
  {name: 'The Complete Operas Of Verdi', writer: 'Charles Osborne', url: 'https://amzn.to/38g9xN8'},
];

const VerdiQuote = {content: 'It is easy to find commonplace, and I can find 50 of them an hour; but it is difficult, very, very difficult, to find one that has all the qualities needed if it is to have an impact, one that is also original and provocative.', quotee: 'Verdi in a letter to the Baritone Felice Varesi'}
const BaseviQuote = {content: 'Verdi was unable to resist the temptation of setting to music, and so making more attractive and acceptable, a filthy and immoral subject, universally loved because the vice it represents is universal.'}

// markup
const TraviataPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>La Traviata | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/traviata" />
          <meta name="description" content="A guide to Giuseppe Verdi's moving opera La Traviata. Including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>

        <Hero title="La Traviata" subtitle="An Opera by Giuseppe Verdi"/>
        <MainBodySection>

          <p><i>La Traviata</i> is one of the cornerstones of the operatic repertory. The score is littered with fantastic tunes that are now wildly famous
          (even those who aren’t at all operatically versed will know many of the arias from countless TV
          ads), but beyond that, the coherence of the whole piece makes it a masterpiece. The taut plot and vivid
          characters paired with Verdi’s perfectly attuned music tend to result in few dry eyes by the end of Act
          III.</p>

          <ImageFull>
            <StaticImage src="../../images/operas/traviata/traviata_ROH_headline.jpg" alt="ROH Traviata 2013"/>
            <figcaption>Traviata at the <a href="http://www.roh.org.uk/productions/la-traviata-by-richard-eyre">Royal Opera House</a> starring <a href="http://ailynperez.com/">Ailyn Pérez</a></figcaption>
          </ImageFull>

          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p>It is currently the most popular of Verdi’s operas and one of his most distinct. The scale is far more intimate than the vast majority of his output, with no grand historical or political elements. The opera concerns itself with
              social issues contemporary to Verdi, almost autobiographical in places with regard to his relationship with Giuseppina Strepponi
              (<Link to="/composers/verdi">you can learn much more on our Verdi page</Link>).
          </p>

          <p>It is also the only one of Verdi’s operas to specifically take place in his own time, “about 1850”. That was his wish, though, for the premiere
              the censors forced him to shift the period, from the contemporary to some hundred years earlier, out of fear
              that the morality on stage might somehow slip out from the proscenium and into the aisles...</p>

          <p>The real wonder of this work is that these characters speak to our universal nature regardless of the period. The plight of Violetta makes sense irrespective of whether we see her as a 19th Century
              courtesan, a 21st Century prostitute or something else altogether!</p>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-103"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Act I - Running Time: 35 mins</SynopsisAct>

            <p>The curtain rises on the Salon of Violetta Valery, a high-class courtesan. She has recently been ill and is throwing a party to celebrate her recovery.
                She is approached by Gastone, who tells her that his friend Alfredo Germont, a young nobleman, is deeply in love with her.
                Alfredo came to her house every day to check up on her while she was sick
                (which sounds a bit creepy to us, but this is a Romantic opera, so we’ll waive our reservations).</p>

            <ImageFull>
              <StaticImage src="../../images/operas/traviata/Traviata_opening_FloridaGrand.jpg" alt="Traviata Act I, Florida Grand Opera"/>
              <figcaption>Traviata Act I, Florida Grand Opera</figcaption>
            </ImageFull>

            <p>Alfredo approaches and admits that all this is true. Baron Douphol, Violetta’s lover of the hour,
                is asked to give a toast but refuses. Alfredo steps up to the plate and sings ‘Libiamo ne' lieti calici’
                otherwise known as the Brindisi, and the first of many highly recognisable tunes.</p>

            <AudioSet audiosrc="/audio/traviata/libiamo_villazon.mp3" desc="'Libiamo ne' lieti calici' sung by Rolando Villazon"/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <p>The band strikes up in the next room, and the guests all head out to dance. Violetta is overcome by dizziness and stays,
                telling her guests she’ll be with them in a minute. She is left alone, but Alfredo returns out of concern and desperate to declare his love. She initially rejects him, but his obsession moves her and as he is about to leave, she gives him a flower telling him to return when it has wilted (which being the next day suggests Alfredo is far from a green thumb).</p>

            <p>Left alone, Violetta delivers one of the grand tours de force of opera, first singing of her enchantment with Alfredo and then of the joy of living ‘always free’, ‘Sempre Libera’.</p>

            <p>We hear Alfredo singing of love from outside as the curtain falls.</p>

            <VideoPlayer src="https://www.youtube.com/embed/m6EChjmi1vU?autoplay=1" id="m6EChjmi1vU" title="Angela Gheorghiu sings 'Sempre libera'"/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act II - Running Time: 65 mins</SynopsisAct>

            <p>The action leaps forward three months, and we are now at Violetta’s country house outside Paris. Our leading pair have fallen in love, and Violetta has abandoned her career as a courtesan. Alfredo sings of his happiness. However, Annina, the maid, arrives from Paris and reveals to Alfredo that she has been to Paris to sell off Violetta’s possessions so they can continue to fund their life in the country (they have no income). He is stunned and immediately leaves for Paris to acquire funds himself.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/traviata/traviata_alfredo_ROH_CatherineAshmore.jpg" alt="Alfredo (Piotr Beczała) discovers Violetta's financial situation, Royal Opera House"/>
              <figcaption>Alfredo (Piotr Beczała) discovers Violetta's financial situation, Royal Opera House</figcaption>
            </ImageFull>

            <p>Violetta returns to find Alfredo gone. She has received a letter from Flora inviting her to a party this evening, but she doesn’t intend to go. Her happiness is quickly blunted by Giorgio Germont’s arrival and his blunt request that she break up with Alfredo. Their relationship is threatening the engagement of Alfredo’s sister (Societal hypocrisy unable to handle a courtesan in a stable relationship). Violetta is initially unable to give up her love, and Giorgio is impressed by her dignity, but he implores her desperately, and she finally agrees.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/traviata/traviata_gandv_santafe_KenHoward.jpg" alt="Violetta (Brenda Rae) and Georgio Germont (Roland Wood), Sante Fe Opera"/>
              <figcaption>Violetta (Brenda Rae) and Georgio Germont (Roland Wood), Sante Fe Opera</figcaption>
            </ImageFull>

            <p>We are now at Flora’s, her party in full swing. The Marquis gossips with Flora telling her that Alfredo and Violetta have separated. The party entertainment arrives, and for no apparent reason we and the guests are treated to a Matador & Gypsy spectacle.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>            

            <p>Violetta arrives with the Baron. Alfredo is also at the party. Excitement ensues with Alfredo and the Baron going head to head at the gambling table. Alfredo wins over and over again. Eventually, Flora announces supper, and Alfredo leaves with a small fortune in winnings. The Baron is less than pleased.</p>

            <p>Everyone leaves, but Violetta has asked Alfredo to meet with her privately. She fears either the Baron or Alfredo will challenge the other to a duel and asks Alfredo to leave before that can happen. Maddened, he demands she tell him to his face that she now loves the Baron. She does so, and he makes a frightful scene calling all the guests back into the room.</p>

            <p>Alfredo vilely humiliates Violetta in front of everyone culminating in him throwing his winnings at her as “payment” for her services. She faints, and the crowd loudly turns on Alfredo. Giorgio Germont arrives, denouncing his son's behaviour. The Act closes with a tremendous ensemble number in which the various characters pour out their hearts.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/traviata/traviata_alfredovile_beatrizschiller_MET.jpg" alt="Alfredo, Act II, MET OPERA"/>
              <figcaption>The close of Act II, Metropolitan Opera</figcaption>
            </ImageFull>

            <SynopsisAct>Act III - Running Time: 35 mins</SynopsisAct>
            
            <p>Months have past, and we are in Violetta’s bedroom. She is on her deathbed, tuberculosis having almost wholly consumed her. Through her illness, all her friends have abandoned her, and she is now penniless. She reads a letter from Giorgio Germont. He has told Alfredo of the sacrifice she made for Alfredo’s sister. Alfredo is on his way to beg for forgiveness. In one of the most moving of arias, “Addio, del passato bei sogni ridenti” - Farewell, happy dreams of the past, Violetta details her fear that Alfredo will not arrive in time…</p>

            <p>Alfredo and Violetta are reunited. Alfredo still believes they have a future together, but it is all too late. Giorgio enters with Doctor Grenvil. They have a short, warm reunion before Violetta feels a sudden surge of energy --  collapses and dies.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/traviata/traviata_final_aixenprovence_PascalVictor.jpg" alt="Traviata Finale, Aix en Provence"/>
              <figcaption>Traviata Finale, Aix en Provence</figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="History"/>

            <p><i>La Traviata</i> came at the end of Verdi’s most frenetic period of composition, finishing what has become known as his Galley Years. It was 1851, and <i>Rigoletto</i> had just premiered to enormous acclaim at La Fenice in Venice. Verdi was already working on <i>Il Trovatore</i> but La Fenice offered him another commission, and by May of 1852, a contract was signed for a premiere in March 1853, though Verdi did not yet even have a subject.</p>

            <BlockQuote quote={VerdiQuote}/>

            <p>Verdi finally chose a subject but then abandoned it after beginning work and began from scratch on a new opera with Francesco Piave, his frequent librettist, based on “La Dame aux camelias” by Alexandre Dumas fils. This novel and then play had been an enormous success, but its themes were highly scandalous for the time. The alarm bells rang quickly, Varesi reportedly informing La Fenice of Verdi’s plan to put this “common whore” on the opera stage. Negative anonymous letters were sent, and to top it off, Verdi was informed that owing to the subject matter, the opera could not be done in modern dress.</p>

            <ImageRight maxwidth="200px">
              <StaticImage src="../../images/operas/traviata/traviata_fanny.jpg" alt="Fanny Salvini-Donatelli"/>
              <figcaption>Fanny Salvini-Donatelli</figcaption>
            </ImageRight>

            <p>Verdi feared the worse: the premiere cast were not nearly what he wanted, especially Fanny Salvini-Donatelli as Violetta, who was the opposite of the slender figure he imagined. He was so concerned he sent Piave to La Fenice to request a casting change but to no avail.</p>

            <p>First night came and went as Verdi feared. Act I fared solidly, but it was all downhill from Act II. Varesi considered Papa Germont unworthy so he put minimal effort in while Lodovico Graziani as Alfredo wasn’t up to snuff. The audience were unkind to Salvini-Donatelli; the legend goes that one shouted, “I see no consumption, only dropsy”. The opening was a disaster though the rest of the run was actually moderately successful financially (there's an upside to a good scandal), and other opera houses would quickly take up the opera.</p>

            <p>Verdi would make a few alterations as the opera was taken up around Europe, and he found his ideal Violetta in Maria Spezia. Slavini-Donatelli was not done with the part; however, she would repeat the role several times!</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Popularity</FunFactsHeader>

            <p>The popularity of <i>La Traviata</i> both then and now is difficult to understate.
                It quickly became one of the most popular operas immediately after its premiere, reaching Vienna by 1855; London, Paris and New York by 1856.
                In the 1856/57 season the Théâtre des Italiens would give 87 performances of which 54 were either <i>La Traviata</i> (the French Premiere), <i>Rigoletto</i> or <i>Il Trovatore</i>.
                Verdi had cornered the market! Not much has changed. Today it is the most performed opera in the world (by some margin).</p>

            <FunFactsHeader>Filthy and immoral</FunFactsHeader>

            <p>Even the supporters of Verdi were not immune to qualms about the content of <i>La Traviata</i>. Abramo Basevi,
                an Italian musicologist, a contemporary of Verdi and the author of the first full-length study of
                Verdi’s output, published in 1859, took issue writing:</p>

            <BlockQuote quote={BaseviQuote}/>


          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-109"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <FunFactsHeader>Brindisi</FunFactsHeader>

            <p>Brindisi is the Italian word for a drinking song, deriving from a German phrase “bring dir's” (Offer it to you).
                There are a fair few examples of them in opera, Verdi also included ones in his <i>Macbeth</i> and <i>Otello</i> though the most famous is the one in <i>La Traviata</i>.
                They generally take the form of a solo singer encouraging the group to sing and the group responding by joining in with the song. </p>

            <VideoPlayer src="https://www.youtube.com/embed/b6qKmAQ8klQ?start=107&autoplay=1" id="b6qKmAQ8klQ" title="Another Brindisi, 'Inaffia l'ugola' from Verdi's Otello sung by Justino Diaz"/>

            <FunFactsHeader>Consumption</FunFactsHeader>

            <p>Violetta is brought to her untimely end by consumption, which is the more glamorous name for the singularly unglamorous disease Tuberculosis (TB). Mimi in <Link to='/operas/boheme'>La bohème</Link> (another TB ridden 19th Century Opera) meets the same fate as have millions of others for much of human history. The 19th Century brought an exceptionally high concentration of TB deaths in part due to much higher population densities in cities: roughly 25% of deaths in Europe during the 1800s were due to TB.</p>

            <ImageRight>
              <StaticImage src="../../images/operas/traviata/traviata_rent.jpeg" alt="Rent - the Musical"/>
              <figcaption>Rent - the Musical</figcaption>
            </ImageRight>

            <p>Somewhat bizarrely, TB became a highly romanticized illness, known as the White Plague, and almost celebrated for its supposed purity. As well as Violetta and Mimi, Antonia in <i>Les Contes d’Hoffmann</i> is dying of TB and the disease features in a good deal of Romantic literature as a somehow noble, beautiful death. Byron went as far as to write, "I should like to die from consumption".</p>

            <p>It wasn’t until Robert Koch’s work in 1882 that doctors understood TB to be contagious, and before the 1940s
                it remained incurable. In his <Link to='/operas/boheme'>La bohème</Link> adaptation <i>Rent</i> Jonathan Larson replaced TB with AIDs, but TB is no disease of the past. Whilst it is true to say that it has been largely eliminated in wealthy nations, it is not nearly as true as it was 30 years ago, in part due to a resurgence of TB in people who are HIV positive. According to the World Health Organization, 1.4 million people died of TB in 2011.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-110"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <FunFactsHeader>Marie Duplessis</FunFactsHeader>

            <p>Marie Duplessis on whom Dumas based his novel (and therefore the real-life Violetta) was born Alphonsine Rose Plessis in Normandy, France 1824. Her journey to become one of the most infamous women in France is not an entirely happy one.</p>

            <ImageRight>
              <StaticImage src="../../images/operas/traviata/Traviata_mariedu.jpg" alt="Marie Duplessis at the opera"/>
              <figcaption>Marie Duplessis</figcaption>
            </ImageRight>

            <p>When she was in her early teens, she was effectively pimped out by her drunkard father to older men. He eventually sent her to Paris, aged 15, to work for a seamstress. As an apprentice, she earned very little
                and was effectively starving when she realised she could achieve far more by ‘working’ as a
                mistress.</p>

            <p>Her successive lovers increased in wealth and importance, and she changed her name to Marie Duplessis (the
                Du adding an air of faux aristocracy). She cultivated a salon attended by the wealthiest of Paris, and made herself one of the most refined women in Paris. However, she developed tuberculosis. Despite the efforts of some of the most famous doctors in France, she died nearly penniless in 1847 at just 23. She achieved an astonishing amount, especially in the context of the gender politics of the
                era, but make no mistake how she did it!</p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default TraviataPage
